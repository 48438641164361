import React, {FC, useRef} from "react";
import {WithBacklinkLayout} from "@layouts/with-backlink-layout";
import {useTranslation} from "react-i18next";
import {Outlet} from "react-router";
import {HeaderTabs} from "@features/header-tabs";

import {ROUTES} from "@routes/routes-list";

const MenuPageLayout: FC = () => {
  const { t } = useTranslation();
  const tabs = useRef([
    {
      title: t("categories"),
      route: ROUTES.MENU_CATEGORIES,
    },
    {
      title: t("modifiers"),
      route: ROUTES.MENU_MODIFIERS,
    },
  ]).current;

  return (
    <WithBacklinkLayout
      headerContent={
        <div
          className={
            "flex gap-3 relative justify-between items-center flex-grow"
          }
        >
          <p className={"flex header-title absolute"}>{t("navigation_menu")}</p>
          <div className={"md:block hidden ml-auto lg:mx-auto"}>
            <HeaderTabs items={tabs} />
          </div>
        </div>
      }
    >
     <div className={'block md:hidden'}>
       <HeaderTabs items={tabs} />
     </div>
      <Outlet />
    </WithBacklinkLayout>
  );
};

export default MenuPageLayout;
