import {fetcher} from "@shared/fetcher";
import {
    ROUTE_ACCEPT_ORDER,
    ROUTE_CALCULATE_ORDER_PRICE,
    ROUTE_CANCEL_ORDER,
    ROUTE_CREATE_ORDER,
    ROUTE_EXPORT_ORDERS,
    ROUTE_FINISH_ORDER,
    ROUTE_GET_COMPANY_ORDERS,
    ROUTE_GET_ORDER_DETAILS,
    ROUTE_GET_ORDER_TIMES,
    ROUTE_ORDER_COMPANY_PREPARATION_FINISH,
    ROUTE_SEND_TO_POS,
    ROUTE_UPDATE_ORDER_PRICE,
    ROUTE_UPDATE_ORDER_TIME
} from "@routes/api_routes";
import {ACTIVE_ORDERS_STATUSES, ORDER_STATUS, ORDER_TYPE, OrderDTO, PAYMENT_TYPE} from "@dto/OrderDTO";
import {WithPagination} from "@dto/WithPagination";
import {OrderDetailsDTO} from "@dto/OrderDetailsDTO";
import {OrderAvailableTimesDTO} from "@dto/OrderAvailableTimesDTO";
import {isAllSelected} from "@shared/lib/helpers";
import {CallCourierDTO} from "@dto/CallCourierDTO";
import {pickBy} from "lodash-es";
import {DELIVERY_TYPE} from "@dto/CompanyDTO";

export type OrdersFilters = {
    statuses?: number[]
    status?: OrderExportStatus
    fromTime?: number
    toTime?: number
    deliveryType?: DELIVERY_TYPE
    paymentType?: PAYMENT_TYPE
    type?: ORDER_TYPE
    limit?: number
    offset?: number
}

export enum OrderExportStatus {
    SUCCESSFUL = 10,
    FAILED = 20,
}

export const OrderRepository = {
    _checkFilters: (filters: OrdersFilters) => {
        filters.statuses = filters.statuses || ACTIVE_ORDERS_STATUSES
        if (filters.status === OrderExportStatus.SUCCESSFUL) filters.statuses = [ORDER_STATUS.FINISHED]
        if (filters.status === OrderExportStatus.FAILED) filters.statuses = [ORDER_STATUS.FAILED, ORDER_STATUS.DECLINED]
        return filters
    },
    getAllOrders: (accountId: string, companyId: string, companyAddressId?: string, filters: OrdersFilters = {}): Promise<WithPagination<OrderDTO>> => {
        if (isAllSelected(companyId)) companyId = ''
        if (isAllSelected(companyAddressId)) companyAddressId = ''

        OrderRepository._checkFilters(filters)

        return fetcher(ROUTE_GET_COMPANY_ORDERS(accountId, companyId, filters, companyAddressId))
    },

    getOrderDetails: (accountId: string, companyId: string, orderId: string,): Promise<OrderDetailsDTO> => {
        if (isAllSelected(companyId)) companyId = ''
        return fetcher(ROUTE_GET_ORDER_DETAILS(accountId, companyId, orderId,))
    },


    acceptOrder: async (accountId: string, companyId: string, orderId: string, time: number | null): Promise<{
        message: string
    }> => {
        if (isAllSelected(companyId)) companyId = ''
        const e = await fetcher<{
            message: string;
        }>(ROUTE_ACCEPT_ORDER(accountId, companyId, orderId), {
            data: pickBy({timeMadeInMinutes: time}, v => v !== null && v !== undefined),
            method: 'POST'
        });
        if (e.message !== 'ok') throw new Error('generic_error');
        return e;
    },

    cancelOrder: (accountId: string, companyId: string, orderId: string): Promise<OrderDTO> => {
        if (isAllSelected(companyId)) companyId = ''
        return fetcher(ROUTE_CANCEL_ORDER(accountId, companyId, orderId), {method: 'POST'})
    },

    sendToPos: (accountId: string, companyId: string, orderId: string): Promise<any> => {
        if (isAllSelected(companyId)) companyId = ''
        return fetcher(ROUTE_SEND_TO_POS(accountId, companyId, orderId), {method: 'POST'})
    },

    getOrderTime: (accountId: string, companyId: string, orderId: string): Promise<WithPagination<OrderAvailableTimesDTO>> => {
        if (isAllSelected(companyId)) companyId = ''
        return fetcher(ROUTE_GET_ORDER_TIMES(accountId, companyId, orderId))
    },

    markAsReady: (accountId: string, companyId: string, orderId: string): Promise<any> => {
        if (isAllSelected(companyId)) companyId = ''
        return fetcher(ROUTE_ORDER_COMPANY_PREPARATION_FINISH(accountId, companyId, orderId), {method: 'POST'})
    },

    finishOrder: (accountId: string, companyId: string, orderId: string) => {
        if (isAllSelected(companyId)) companyId = ''
        return fetcher(ROUTE_FINISH_ORDER(accountId, companyId, orderId), {method: 'POST'})
    },

    updateOrderPrice: (accountId: string, companyId: string, orderId: string, price: number): Promise<any> => {
        if (isAllSelected(companyId)) companyId = ''
        return fetcher(ROUTE_UPDATE_ORDER_PRICE(accountId, companyId, orderId), {data: {price}, method: 'POST'})
    },

    updateOrderTime: (accountId: string, companyId: string, orderId: string, time: number): Promise<any> => {
        if (isAllSelected(companyId)) companyId = ''
        return fetcher(ROUTE_UPDATE_ORDER_TIME(accountId, companyId, orderId), {
            data: {timeMadeIn: time},
            method: 'POST'
        })
    },

    createOrder: (accountId: string, companyId: string, companyAddressId: string, data: CallCourierDTO) => {
        const payload = pickBy({
            ...data,
            deliveryType: +data.deliveryType,
            deliveryAddress: {
                street: data.street,
                house: data.house,
                flat: data.flat,
                stage: data.stage,
                note: data.addressNote,
            }
        }, x => x !== undefined && x !== null && x !== '')
        if (isAllSelected(companyId)) companyId = ''
        return fetcher(ROUTE_CREATE_ORDER(accountId, companyId, companyAddressId), {
            data: payload, method: 'POST'
        })
    },

    calculateOrderDeliveryPrice: (accountId: string, companyId: string, companyAddressId: string, data: CallCourierDTO) => {
        const payload = pickBy({
            ...data,
            deliveryType: +data.deliveryType,
            deliveryAddress: {
                street: data.street,
                house: data.house,
                flat: data.flat,
                stage: data.stage,
                note: data.addressNote,
            }
        })
        if (isAllSelected(companyId)) companyId = ''
        if (isAllSelected(companyAddressId)) companyAddressId = ''
        return fetcher<{
            deliveryPrice: number,
            distance: number
        }>(ROUTE_CALCULATE_ORDER_PRICE(accountId, companyId, companyAddressId), {
            data: payload, method: 'POST'
        })
    },

    exportOrders: (accountId: string, companyId: string, companyAddressId: string, filters: OrdersFilters = {}) => {
        if (isAllSelected(companyId)) companyId = ''
        if (isAllSelected(companyAddressId)) companyAddressId = ''

        OrderRepository._checkFilters(filters)

        return fetcher<string>(ROUTE_EXPORT_ORDERS(accountId, companyId, companyAddressId, filters));

    }
}