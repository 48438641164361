import {groupBy} from "lodash-es";
import dayjs from "dayjs";


export enum GroupingInterval {
    HOUR = 'hour',
    MINUTE = 'minute',
}

const roundings = {
    [GroupingInterval.HOUR]: 1,
    [GroupingInterval.MINUTE]: 15
}
export const groupByTime = <T extends Object>(entities: T[], by: keyof T, groupingInterval: GroupingInterval = GroupingInterval.HOUR, timeRounding?: number) => {
    if (!entities.length) return {}
    if (!timeRounding) timeRounding = roundings[groupingInterval]

    let byKey = groupBy(entities.filter(row => !!row[by]), row => {
        let time = dayjs.unix(row[by] as number)

        switch (groupingInterval) {
            default:
            case GroupingInterval.HOUR:
                let fullHours = time.hour() - (time.hour() % timeRounding)
                return dayjs().hour(fullHours).minute(0).second(0).millisecond(0).unix()
            case GroupingInterval.MINUTE:
                const fullMinutes = time.minute() - (time.minute() % timeRounding)
                return dayjs().hour(time.hour()).minute(fullMinutes).second(0).millisecond(0).unix()
        }
    })
    const res = {}

    for (const row in byKey) {
        res[row] = byKey[row].length || 0
    }

    return res
}

