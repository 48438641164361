import React, { FC, PropsWithChildren, ReactElement } from "react";
import { Header, HeaderProps } from "@features/header/header";

export const WithBacklinkLayout: FC<
  PropsWithChildren & HeaderProps & { headerContent: ReactElement }
> = ({ children, headerContent, ...props }) => {
  return (
    <div>
      <Header
        showMenuButton={false}
        showBackButton={true}
        showNotificationButton={false}
        {...props}
        children={headerContent}
      />
      <div className="content-main content-main-padding">{children}</div>
    </div>
  );
};
