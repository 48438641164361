import {createStore, useStore} from 'zustand'

interface IState {
    isOpen: boolean
    onConfirm?: () => Promise<any>
    setConfirm: (val: () => Promise<any>) => void
    setOpen: (val: boolean) => void
}
const state = createStore<IState>(set => ({
    isOpen: false,
    setConfirm: (val: () => Promise<any>) => {
        set({onConfirm: val})
    },
    setOpen: (val: boolean) => set({isOpen: val})
}))
export const useActionConfirmation = () => {
    const store = useStore(state)
    return {
        isOpen: store.isOpen,
        showConfirmation: (cb: () => Promise<any>) => {
            store.setConfirm(cb)
            store.setOpen(true)
        },
        onConfirm: async () => {
            return store.onConfirm?.().then(() => store.setOpen(false))
        },
        onCancel: async () => {
            store.setOpen(false);
        }
    }
}