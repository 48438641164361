import React, {FC} from "react";
import {useTranslation} from "react-i18next";
import {Link, useRouteError} from "react-router-dom";
import {AxiosError} from "axios";
import {getErrorMessage} from "@shared/lib/helpers";

export const GenericError: FC<{ status?: number }> = ({ status, ...rest }) => {
  const { t } = useTranslation();
  const error = useRouteError();

  const getErrorText = () => {
    return getErrorMessage(error)
  };

  const getErrorStatus = () => {
    if (error instanceof AxiosError) {
      return error.status;
    }

    return status;
  };

  return (
    <div className={"w-svw h-svh flex flex-col items-center justify-center"}>
      <div
        className={"flex mb-5 gap-4 items-center divide-x with-divide-color"}
      >
        <p className={"font-bold text-[90px]/[120px]"}>{getErrorStatus()}</p>
        <p className={"text-2xl pl-4"}>{t("error_text")}</p>
      </div>
      {!!error && (
        <pre
          className={
            "text-lg mb-4 max-w-[900px] overflow-auto break-all text-wrap max-h-[400px] bg-gray-300 dark:bg-gray-600 font-mono rounded-lg p-3"
          }
        >
          {getErrorText()}
        </pre>
      )}

      <Link className={"btn btn-info py-4 px-10"} to={"/"}>
        {t("home page")}
      </Link>
    </div>
  );
};
