export const BASE_PATH = "/:lang/:accountId/:companyId/:companyAddressId";

export const ROUTES = {
  NEW_ORDERS: BASE_PATH + "/new",
  NOW_ORDERS: BASE_PATH + "/now",
  LATER_ORDERS: BASE_PATH + "/later",
  REVIEWS: BASE_PATH + "/reviews",
  CALL_COURIER: BASE_PATH + "/call-courier",
  ARCHIVE: BASE_PATH + "/archive",
  STATISTICS: BASE_PATH + "/statistics",
  MENU: BASE_PATH + "/menu",
  MENU_CATEGORIES: BASE_PATH + "/menu/categories",
  MENU_MODIFIERS: BASE_PATH + "/menu/modifiers",
  SETTINGS: BASE_PATH + "/settings",
  NOTIFICATIONS: BASE_PATH + "/notifications",

  LOGIN: "/:lang/login",
} as const;
