import {attempt, isObject, isUndefined} from "lodash-es";

export class StorageManager {
  static KEYS = {
    AUTH_TOKEN: "token",
    LAST_COMPANY_ID: "company_id",
    LAST_ACCOUNT_ID: "account_id",
    LAST_LANG: "lang",
    LAST_ADDRESS_ID: "address_id",
    USER_ID: 'uid',
    USER_LEVEL: 'level',
    USER_PERMISSIONS: 'permissions',
    SHOW_TEST_ORDERS: 'show_test_orders',
    THEME: 'theme',
  } as const;

  static getFromLocal(key: string, fallback?: any) {
    if (isUndefined(fallback)) fallback = null;
    let text: string | null = null;

    try {
      text = localStorage.getItem(key);
    } catch {}
    const val =
      this.tryParse<{ expired?: number; value?: any } | any>(text) ?? text;
    if (val && val?.expired) {
      if (val.expired <= Date.now()) {
        this.removeFromLocal(key);
        return fallback;
      }
      return val?.value ?? fallback;
    }
    return val ?? fallback;
  }

  /**
   *
   * @param key
   * @param value
   * @param expired - in minutes
   */
  static setToLocal(key: string, value?: any, expired?: number) {
    if (expired) {
      expired = Date.now() + expired * 60 * 1000;
      attempt(() =>
        localStorage.setItem(
          key,
          JSON.stringify({
            expired,
            value,
          }),
        ),
      );
      return;
    }
    attempt(() =>
      localStorage.setItem(
        key,
        isObject(value) ? JSON.stringify(value) : value,
      ),
    );
  }

  static removeFromLocal(key: string) {
    attempt(() => localStorage.removeItem(key));
  }

  static removeFromSession(key: string) {
    attempt(() => sessionStorage.removeItem(key));
  }

  static getFromSession(key: string, fallback?: any) {
    if (isUndefined(fallback)) fallback = null;

    let text: string | null = null;

    try {
      text = sessionStorage.getItem(key);
    } catch {}
    const val = this.tryParse(text) ?? text;
    return val ?? fallback;
  }

  static setToSession(key: string, value?: any) {
    attempt(() =>
      sessionStorage.setItem(
        key,
        isObject(value) ? JSON.stringify(value) : value,
      ),
    );
  }

  static tryParse<T>(json: string | null) {
    try {
      if (!json) return null;
      return JSON.parse(json) as T;
    } catch (e) {
      return null;
    }
  }

  static searchAndRemove(key: string) {
    try {
      const keys = Object.keys(localStorage);
      keys
        .filter((e) => e.includes(key))
        .forEach((e) => localStorage.removeItem(e));
      keys
        .filter((e) => e.includes(key))
        .forEach((e) => sessionStorage.removeItem(e));
    } catch (e) {}
  }
}
