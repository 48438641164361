import qs from 'qs'
import {pickBy} from "lodash-es";

const prepareParams = (params: Record<string, any>) => qs.stringify(pickBy(params, x => x !== undefined && x !== null && x !== ''))

const API_VERSION = process.env.REAPT_APP_API_VERSION || 'v1'
const API_PREFIX = process.env.REACT_APP_API_PREFIX

type BaseRouteWithOrder = (accountId: string, companyId: string, orderId: string) => string
type BaseRouteWithOrderAndPayment = (accountId: string, companyId: string, orderId: string, paymentId: string) => string
type BaseRoute = (accountId: string, companyId: string, companyAddressId?: string) => string
type GetOrdersRoute = (accountId: string, companyId: string, filters: Record<string,any>, companyAddressId?: string) => string
type NotificationsRoute = (accountId: string, companyId: string, notificationId: string, companyAddressId?: string) => string
export const ROUTE_SIGN_IN = `${API_PREFIX}/${API_VERSION}/auth/sign-in` as const

export const ROUTE_GET_COMPANY_ORDERS: GetOrdersRoute = (accountId, companyId, filters = {}, companyAddressId) => {
    return `${API_PREFIX}/${API_VERSION}/accounts/${accountId}/orders?${prepareParams({
        addressId: companyAddressId,
        companyId,
        ...filters
    })}`;
}

export const ROUTE_GET_ORDER_DETAILS: BaseRouteWithOrder = (accountId: string, companyId: string, orderId: string,) => {
    return `${API_PREFIX}/${API_VERSION}/accounts/${accountId}/orders/${orderId}?${prepareParams({companyId})}`
}

export const ROUTE_ACCEPT_ORDER: BaseRouteWithOrder = (accountId, companyId, orderId) => {
    return `${API_PREFIX}/${API_VERSION}/accounts/${accountId}/orders/${orderId}/accept?${prepareParams({companyId})}`
}

export const ROUTE_CANCEL_ORDER: BaseRouteWithOrder = (accountId, companyId, orderId) => {
    return `${API_PREFIX}/${API_VERSION}/accounts/${accountId}/orders/${orderId}/cancel?${prepareParams({companyId})}`
}

export const ROUTE_ORDER_COMPANY_PREPARATION_FINISH: BaseRouteWithOrder = (accountId, companyId, orderId) => {
    return `${API_PREFIX}/${API_VERSION}/accounts/${accountId}/orders/${orderId}/company-preparation-finish?${prepareParams({companyId})}`
}

export const ROUTE_FINISH_ORDER: BaseRouteWithOrder = (accountId, companyId, orderId) => {
    return `${API_PREFIX}/${API_VERSION}/accounts/${accountId}/orders/${orderId}/company-finish?${prepareParams({companyId})}`
}



export const ROUTE_GET_ORDER_TIMES: BaseRouteWithOrder = (accountId, companyId, orderId) => {
    return `${API_PREFIX}/${API_VERSION}/accounts/${accountId}/orders/${orderId}/made-in-available-times?${prepareParams({companyId})}`
}

export const ROUTE_GET_ORDER_PAYMENTS: BaseRouteWithOrder = (accountId, companyId, orderId) => {
    return `${API_PREFIX}/${API_VERSION}/accounts/${accountId}/orders/${orderId}/payments/purchase?${prepareParams({companyId})}`
}

export const ROUTE_UPDATE_ORDER_PRICE: BaseRouteWithOrder = (accountId, companyId, orderId) => {
    return `${API_PREFIX}/${API_VERSION}/accounts/${accountId}/orders/${orderId}/price?${prepareParams({companyId})}`
}

export const ROUTE_UPDATE_ORDER_TIME: BaseRouteWithOrder = (accountId, companyId, orderId) => {
    return `${API_PREFIX}/${API_VERSION}/accounts/${accountId}/orders/${orderId}/time-made-in?${prepareParams({companyId})}`
}

export const ROUTE_SEND_TO_POS: BaseRouteWithOrder = (accountId, companyId, orderId) => {
    return `${API_PREFIX}/${API_VERSION}/accounts/${accountId}/orders/${orderId}/send-to-pos?${prepareParams({companyId})}`
}

export const ROUTE_SEND_PAYMENT_LINK: BaseRouteWithOrder = (accountId, companyId, orderId) => {
    return `${API_PREFIX}/${API_VERSION}/accounts/${accountId}/orders/${orderId}/payments/purchase/send-payment-link?${prepareParams({companyId})}`
}

export const ROUTE_SYNC_PAYMENT: BaseRouteWithOrderAndPayment = (accountId, companyId, orderId, paymentId) => {
    return `${API_PREFIX}/${API_VERSION}/accounts/${accountId}/orders/${orderId}/payments/${paymentId}?${prepareParams({companyId})}`
}

export const ROUTE_GET_COMPANY_ADDRESSES = (accountId: string, companyId: string) => {
    return `${API_PREFIX}/${API_VERSION}/accounts/${accountId}/companies/${companyId}/addresses`
}

export const ROUTE_GET_COMPANY_ADDRESS: BaseRoute = (accountId, companyId, companyAddressId) => {
    return `${API_PREFIX}/${API_VERSION}/accounts/${accountId}/companies/${companyId}/addresses/${companyAddressId}`
}

export const ROUTE_GET_COMPANY: BaseRoute = (accountId, companyId) => {
    return `${API_PREFIX}/${API_VERSION}/accounts/${accountId}/companies/${companyId}`
}

export const ROUTE_UPDATE_COMPANY_STATUS: BaseRoute = (accountId, companyId, companyAddressId) => {
    return `${API_PREFIX}/${API_VERSION}/accounts/${accountId}/companies/${companyId}/addresses/${companyAddressId}?${prepareParams({addressId: companyAddressId})}`
}


export const ROUTE_GET_USER_COMPANIES = (accountId: string, userId: string) => {
    return `${API_PREFIX}/${API_VERSION}/accounts/${accountId}/users/${userId}/companies `
}

export const ROUTE_GET_NOTIFICATIONS: BaseRoute = (accountId, companyId, companyAddressId) => {
    return `${API_PREFIX}/${API_VERSION}/accounts/${accountId}/notifications?${prepareParams({addressId: companyAddressId, companyId})}`
}

export const ROUTE_HAS_NOTIFICATIONS: BaseRoute = (accountId, companyId, companyAddressId) => {
    return `${API_PREFIX}/${API_VERSION}/accounts/${accountId}/notifications/has-new?${prepareParams({addressId: companyAddressId, companyId})}`
}

export const ROUTE_ACCEPT_ALL_NOTIFICATIONS: NotificationsRoute = (accountId, companyId, notificationId, companyAddressId) => {
    return `${API_PREFIX}/${API_VERSION}/accounts/${accountId}/notifications/accept-all?${prepareParams({addressId: companyAddressId, companyId})}`
}

export const ROUTE_ACCEPT_NOTIFICATION: NotificationsRoute = (accountId, companyId, notificationId, companyAddressId) => {
    return `${API_PREFIX}/${API_VERSION}/accounts/${accountId}/addresses/${companyAddressId}/notifications/${notificationId}/accept?${prepareParams({companyId})}`
}

export const ROUTE_GET_COMPANY_CATEGORIES = (accountId: string, companyId: string, categoryId: string = '') => {
    return `${API_PREFIX}/${API_VERSION}/accounts/${accountId}/companies/${companyId}/categories/${categoryId}`
}

export const ROUTE_GET_COMPANY_CATEGORIES_ITEMS = (accountId: string, companyId: string, itemId: string = '') => {
    return `${API_PREFIX}/${API_VERSION}/accounts/${accountId}/companies/${companyId}/items/${itemId}`
}

export const ROUTE_GET_COMPANY_MODIFIER_GROUPS = (accountId: string, companyId: string, modifierId: string = '') => {
    return `${API_PREFIX}/${API_VERSION}/accounts/${accountId}/companies/${companyId}/modifier-groups/${modifierId}`
}

export const ROUTE_GET_MODIFIERS_GROUPS_MODIFIERS = (accountId: string, companyId: string, modifierId: string = '') => {
    return `${API_PREFIX}/${API_VERSION}/accounts/${accountId}/companies/${companyId}/modifiers/${modifierId}`
}

export const ROUTE_GET_REVIEWS = (accountId: string, companyId?: string, companyAddressId?: string, params?: Record<string, any>) => {
    return `${API_PREFIX}/${API_VERSION}/accounts/${accountId}/reviews?${qs.stringify(pickBy({
        ...params,
        companyId,
        addressId: companyAddressId
    }, Boolean))}`
}

export const ROUTE_ACCEPT_REVIEW = (accountId: string, companyId: string, reviewId: string) => {
    return `${API_PREFIX}/${API_VERSION}/accounts/${accountId}/reviews/${reviewId}/accept?${prepareParams({companyId})}`
}

export const ROUTE_ACCEPT_DECLINE = (accountId: string, companyId: string, reviewId: string) => {
    return `${API_PREFIX}/${API_VERSION}/accounts/${accountId}/reviews/${reviewId}/cancel?${prepareParams({companyId})}`
}

export const ROUTE_CREATE_ORDER = (accountId: string, companyId: string, companyAddressId: string) => {
    return `${API_PREFIX}/${API_VERSION}/accounts/${accountId}/orders?${prepareParams({companyId, accountId, addressId: companyAddressId})}`
}

export const ROUTE_CALCULATE_ORDER_PRICE = (accountId: string, companyId: string, companyAddressId: string) => {
    return `${API_PREFIX}/${API_VERSION}/accounts/${accountId}/orders/calculate-delivery-price?${prepareParams({companyId, addressId: companyAddressId})}`
}

export const ROUTE_GET_ACCOUNT_SETTINGS = (accountId: string) => {
    return `${API_PREFIX}/${API_VERSION}/accounts/${accountId}/settings`
}

export const ROUTE_GET_STATISTICS = (accountId: string, companyId?: string, companyAddressId?: string, filters: Record<string, any> = {}) => {
    return `${API_PREFIX}/${API_VERSION}/accounts/${accountId}/statistics?${prepareParams({companyId, addressId: companyAddressId, ...filters})}`
}

export const ROUTE_EXPORT_ORDERS = (accountId: string, companyId?: string, companyAddressId?: string, filters: Record<string, any> = {}) => {
    return `${API_PREFIX}/${API_VERSION}/accounts/${accountId}/export-orders?${prepareParams({companyId, addressId: companyAddressId, ...filters})}`
}