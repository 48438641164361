import useSWR from "swr";
import {NotificationsRepository} from "@src/repositories/NotificationsRepository";
import {useParams} from "react-router";
import {ORDERS_REFRESH_INTERVAL} from "@src/config";

export const useCompanyNotifications = (load= true) => {
    const {accountId, companyId, companyAddressId,} = useParams()
    return useSWR(companyId && load && `/no-cache/${companyId}/notifications`, () => NotificationsRepository.getAll(accountId as string, companyId as string, companyAddressId), {
        refreshInterval: ORDERS_REFRESH_INTERVAL,
        dedupingInterval: ORDERS_REFRESH_INTERVAL
    })
}

export const useHasNewNotifications = () => {
    const {accountId, companyId, companyAddressId,} = useParams()

    return useSWR(`/no-cache/notifications/has-new`, () => NotificationsRepository.hasNew(accountId as string, companyId as string, companyAddressId), {
        refreshInterval: ORDERS_REFRESH_INTERVAL,
        dedupingInterval: ORDERS_REFRESH_INTERVAL
    })
}