import React, { FC, PropsWithChildren, ReactElement } from "react";
import { Header } from "@features/header/header";
import { Sidebar } from "@entities/sidebar/sidebar";

export const WithMenuLayout: FC<
  PropsWithChildren & { headerContent: ReactElement }
> = ({ children, headerContent }) => {
  return (
    <div>
      <Header showMenuButton showNotificationButton children={headerContent} />
      <Sidebar />
      <div className="content-main content-main-padding">{children}</div>
    </div>
  );
};
