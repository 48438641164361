import {useTranslation} from "react-i18next";
import React, {FC, PropsWithChildren} from "react";
import {WithMenuLayout} from "@layouts/with-menu-layout";
import {HeaderTabs} from "@features/header-tabs";
import {Outlet} from "react-router";
import {ROUTES} from "@routes/routes-list";
import {useOrdersList} from "@entities/orders/lib/hooks";
import {
    new_orders_tab_selector,
    now_orders_tab_selector,
    pre_orders_tab_selector
} from "@entities/orders/lib/selectors";

export const OrdersPageLayout: FC<PropsWithChildren> = ({children}) => {
    const {t} = useTranslation();
    const {groupedOrders} = useOrdersList()
    const headerLinks = [
        {
            title: t("new_orders"),
            route: ROUTES.NEW_ORDERS,
            badge: String(groupedOrders.newOrders?.length || ''),
            'data-testid': new_orders_tab_selector,
        },
        {
            title: t("now_orders"),
            route: ROUTES.NOW_ORDERS,
            badge: String(groupedOrders.now?.length || ''),
            'data-testid': now_orders_tab_selector,
        },
        {
            title: t("later_orders"),
            route: ROUTES.LATER_ORDERS,
            badge: String(groupedOrders.preorder?.length || ''),
            'data-testid': pre_orders_tab_selector,
        },
    ];

    return (
        <WithMenuLayout headerContent={<HeaderTabs items={headerLinks}/>}>
            <Outlet/>
        </WithMenuLayout>
    );
};
