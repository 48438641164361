import {useNavigate, useParams} from "react-router";
import {formatUrl} from "@shared/lib/helpers";

export const useRouteBuilder = () => {
    const {companyId, accountId, companyAddressId, lang} = useParams<{
        companyId: string;
        accountId: string;
        lang: string;
        companyAddressId: string;
    }>();
    const navigate = useNavigate()

    return {
        buildRoute: (
            path: string,
            params?: Record<string, string | number | undefined>,
        ) => formatUrl(path, {companyId, companyAddressId, accountId, lang, ...params}),
        navigate: (path: string,
                   params?: Record<string, string | number | undefined>,) => {
            navigate(formatUrl(path, {companyId, companyAddressId, accountId, lang, ...params}))
        }
    };
};
