export interface CompanyAddressDTO {
    id: string
    companyId: string
    address: string
    status: COMPANY_ADDRESS_STATUS
    reviewsCount: number
    rating: number
    activePOSProvider: "poster"
    workTime: {id: number, start: string, end: string, status: 0|1}[]

}

export enum COMPANY_ADDRESS_STATUS {
    INACTIVE = 0,
    ACTIVE = 1,
    HIDDEN = 4,
}