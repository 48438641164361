import {StorageManager} from "@features/storage-manager/storage-manager";

export const localStorageCacheProvider = (): Map<string, any> => {
    const cache_key = require('../../../package.json').version
    // When initializing, we restore the data from `localStorage` into a map.
    const map = new Map<string, any>((StorageManager.getFromSession(cache_key, [])))
    // Before unloading the app, we write back all the data into `localStorage`.
    window.addEventListener('beforeunload', () => {
        const backup = Array.from(map.entries()).filter(e => !e[0].includes('no-cache'))
        StorageManager.setToSession(cache_key, backup)
    })

    // We still use the map for write & read for performance.
    return map
};