import {useEffect, useState} from "react";
import {useMatchMedia} from "@shared/lib/useMatchMedia";
import {StorageManager} from "@features/storage-manager/storage-manager";

export const useTheme = () => {
    const [theme, setTheme] = useState(StorageManager.getFromLocal(StorageManager.KEYS.THEME, 'light'));
    const preferDark = useMatchMedia("(prefers-color-scheme: dark)");

    const changeTheme = (val: string) => {
        const isDark = val === "dark";
        StorageManager.setToLocal(StorageManager.KEYS.THEME, val)
        document.body.classList[isDark ? "add" : "remove"]("dark");
        setTheme(val);
    }

    useEffect(() => {
        if (preferDark && !StorageManager.getFromLocal(StorageManager.KEYS.THEME)) {
            changeTheme("dark")
        }
        if(StorageManager.getFromLocal(StorageManager.KEYS.THEME)){
            changeTheme(StorageManager.getFromLocal(StorageManager.KEYS.THEME))
        }
    }, [preferDark])

    return {
        theme,
        isDark: () => theme === "dark",
        isLight: () => theme === "light",
        setTheme: changeTheme,
    };
};
