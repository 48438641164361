import React, { Component, PropsWithChildren } from "react";
import { GenericError } from "@features/error-pages/generic-error";

export class ErrorBoundary extends Component<PropsWithChildren> {
  state = { hasError: false };

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    this.setState({ hasError: true });
    console.log(error);
  }

  render() {
    if (!this.state.hasError) return this.props.children;
    return <GenericError status={500} />;
  }
}
