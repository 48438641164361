import {CooperationType} from "@dto/OrderDTO";

export enum COMPANY_STATUS {
    INACTIVE = 0,
    ACTIVE = 1,
    PAUSED = 2,
    SOON = 3,
    HIDDEN = 4,
    DELETED = 100,
}

export enum DELIVERY_TYPE {
    TO_HOUSE = 0,
    TO_DOOR = 1,
    PICKUP = 2,
    BOOKING = 3,
    INNER = 4,
    INNER_TO_ROOM = 5,
}

export const NON_DELIVERABLE_TYPES = [
    DELIVERY_TYPE.PICKUP,
    DELIVERY_TYPE.BOOKING,
    DELIVERY_TYPE.INNER,
    DELIVERY_TYPE.INNER_TO_ROOM,
];

export const DELIVERABLE_TYPES = [
    DELIVERY_TYPE.TO_HOUSE,
    DELIVERY_TYPE.TO_DOOR,
];

export const NON_PACKAGABLE_TYPES = [
    DELIVERY_TYPE.INNER,
    DELIVERY_TYPE.BOOKING,
    DELIVERY_TYPE.INNER_TO_ROOM,
];

export interface CompanyDTO {
    status: COMPANY_STATUS;
    id: string;
    name: string;
}

export interface FullCompanyDTO extends CompanyDTO {
    cooperation: CooperationType;
    name: string
    rating: number
    reviewsCount: number
}
