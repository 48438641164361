import {fetcher} from "@shared/fetcher";
import {
    ROUTE_ACCEPT_ALL_NOTIFICATIONS,
    ROUTE_ACCEPT_NOTIFICATION,
    ROUTE_GET_NOTIFICATIONS,
    ROUTE_HAS_NOTIFICATIONS
} from "@routes/api_routes";
import {NotificationsDTO} from "@dto/NotificationsDTO";
import {isAllSelected} from "@shared/lib/helpers";

export const NotificationsRepository = {
    getAll: (accountId: string, companyId: string, companyAddressId?: string) => {
        if (isAllSelected(companyAddressId)) companyAddressId = undefined
        if(isAllSelected(companyId)) companyId = ''

        return fetcher<NotificationsDTO>(ROUTE_GET_NOTIFICATIONS(accountId, companyId, companyAddressId))
    },
    hasNew(accountId: string, companyId: string, companyAddressId?: string){
        if (isAllSelected(companyAddressId)) companyAddressId = undefined
        if(isAllSelected(companyId)) companyId = ''
      return fetcher<{hasNotifications: boolean}>(ROUTE_HAS_NOTIFICATIONS(accountId, companyId, companyAddressId))
    },
    acceptNotification: (accountId: string, companyId: string, notificationId: string, companyAddressId?: string) => {
        if (isAllSelected(companyAddressId)) companyAddressId = undefined
        if(isAllSelected(companyId)) companyId = ''
        return fetcher(ROUTE_ACCEPT_NOTIFICATION(accountId, companyId, notificationId, companyAddressId), {method: 'POST'})
    },
    acceptAllNotifications: (accountId: string, companyId: string, companyAddressId?: string) => {
        if (isAllSelected(companyAddressId)) companyAddressId = undefined
        if(isAllSelected(companyId)) companyId = ''
        return fetcher(ROUTE_ACCEPT_ALL_NOTIFICATIONS(accountId, companyId, 'all', companyAddressId), {method: 'POST'})
    }
}