import {useEffect} from "react";
import {StorageManager} from '@features/storage-manager/storage-manager'
import {createStore, useStore} from "zustand";
import {useParams} from "react-router";
import {isAllSelected} from "@shared/lib/helpers";

type IAuthStore = {
    userId: string,
    setUserId: (userId: string) => void
    setLevel: (level: number) => void
    setPermissions: (permissions: string[]) => void
    level: number,
    permissions: string[]
}
const authStore = createStore<IAuthStore>((set) => ({
    userId: '',
    level: 0,
    permissions: [],
    setUserId: (userId: string) => set({userId}),
    setLevel: (level: number) => set({level}),
    setPermissions: (permissions: string[]) => set({permissions})
}))

export const useAuth = () => {
    const store = useStore(authStore)
    const {companyId} = useParams()
    useEffect(() => {
        mount()
    }, [])

    const mount = () => {
        const uid = StorageManager.getFromLocal(StorageManager.KEYS.USER_ID, '')
        const level = StorageManager.getFromLocal(StorageManager.KEYS.USER_LEVEL, 0);
        const permissions = StorageManager.getFromLocal(StorageManager.KEYS.USER_PERMISSIONS, []);
        store.setLevel(level)
        store.setPermissions(permissions)
        store.setUserId(uid)
    }

    return {
        userId: store.userId,
        level: store.level,
        permissions: store.permissions,
        isAuthorized: !!store.userId,
        isAllCompaniesSelected: isAllSelected(companyId),
        mount,
        canSelectAllCompanies: () => store.level === 3 || store.permissions.includes('company.index'),
        isAdmin: () => store.level === 3,
    }
}