import React, {FC, PropsWithChildren} from "react";
import classNames from "classnames";

export const Toggle: FC<
  { checked: boolean; loading?: boolean,onChange?: (val: boolean) => void } & PropsWithChildren
> = ({ checked, onChange,loading, children, ...rest }) => {
  return (
    <label {...rest} className={classNames('inline-flex items-center cursor-pointer', {skeleton: loading})}>
      <input
        onChange={() => onChange && onChange(!checked)}
        type="checkbox"
        checked={checked}
        disabled={loading}
        className="sr-only peer"
      />
      <div className={classNames({invisible: loading},'relative w-11 h-6 bg-gray-300 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-gray-200 dark:peer-focus:ring-gray-800 rounded-full peer dark:bg-gray-500 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[\'\'] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-green-500')}></div>
      {children && (
        <p className="ms-3 text-lg text-gray-900 dark:text-gray-300">
          {children}
        </p>
      )}
    </label>
  );
};
