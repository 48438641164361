import React from "react";
import {useTheme} from "@features/theme/hooks";

export const FullPageLoader = () => {
  const { isDark } = useTheme();

  return (
    <div
      className={
        "fixed flex items-center justify-center h-svh w-svw z-50 inset-0 bg-white dark:bg-gray-900 animate__animated animate__fadeIn"
      }
    >
      <img
        src={
          isDark()
            ? require("@src/assets/dots-logo-d.png")
            : require("@src/assets/dots-logo-d-white.png")
        }
        className={"max-w-[300px] block h-auto animate-pulse"}
        alt={"Dots Platform"}
      />
    </div>
  );
};
