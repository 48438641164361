import {OrderDTO} from "@dto/OrderDTO";
import dayjs from "dayjs";
import cpf from 'dayjs/plugin/customParseFormat'
import {groupByTime, GroupingInterval} from "@shared/lib/groupByTime";

dayjs.extend(cpf)


export const getOrdersSchedule = (orders: OrderDTO[], groupingInterval: GroupingInterval = GroupingInterval.HOUR, timeRounding ?: number): Record<number, number> => {
    return groupByTime(orders, 'timeMadeIn', groupingInterval, timeRounding)
}


