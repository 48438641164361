import {invert, memoize, uniq} from "lodash-es";

const charMapUa = {
    q: "й",
    w: "ц",
    e: "у",
    r: "к",
    t: "е",
    y: "н",
    u: "г",
    i: "ш",
    o: "щ",
    p: "з",
    "[": "х",
    "]": "ї",
    "\\": "є",
    a: "ф",
    s: "і",
    d: "в",
    f: "а",
    g: "п",
    h: "р",
    j: "о",
    k: "л",
    l: "д",
    ";": "ж",
    "'": "є",
    z: "я",
    x: "ч",
    c: "с",
    v: "м",
    b: "и",
    n: "т",
    m: "ь",
    ",": "б",
    ".": "ю",
    "/": ".",
    "`": "ґ",
};

const charMapRu = {
    q: "й",
    w: "ц",
    e: "у",
    r: "к",
    t: "е",
    y: "н",
    u: "г",
    i: "ш",
    o: "щ",
    p: "з",
    "[": "х",
    "]": "ъ",
    a: "ф",
    s: "ы",
    d: "в",
    f: "а",
    g: "п",
    h: "р",
    j: "о",
    k: "л",
    l: "д",
    ";": "ж",
    "'": "э",
    z: "я",
    x: "ч",
    c: "с",
    v: "м",
    b: "и",
    n: "т",
    m: "ь",
    ",": "б",
    ".": "ю",
    "/": ".",
    "`": "ё",
    Q: "Й",
    W: "Ц",
    E: "У",
    R: "К",
    T: "Е",
    Y: "Н",
    U: "Г",
    I: "Ш",
    O: "Щ",
    P: "З",
    "{": "Х",
    "}": "Ъ",
    A: "Ф",
    S: "Ы",
    D: "В",
    F: "А",
    G: "П",
    H: "Р",
    J: "О",
    K: "Л",
    L: "Д",
    ":": "Ж",
    '"': "Э",
    Z: "Я",
    X: "Ч",
    C: "С",
    V: "М",
    B: "И",
    N: "Т",
    M: "Ь",
    "<": "Б",
    ">": "Ю",
    "?": ",",
    "~": "Ё",
};


const charMapUaInverse = invert(charMapUa);

const charMapRuInverse = invert(charMapRu);

/**
 * This code will search in strings and array with inverse case. When keyboard layout is not correct.
 * @example SearchWithInverseCase findInStringWithInverseCase('text', 'еуче')
 * @example SearchWithInverseCase filterInArrayWithInverseCase(['text'], 'еуче')
 * @type {{findInArrayWithInverseCase: (function(Array, string): *|null), filterInArrayWithInverseCase: (function(Array, string): *|null), findInStringWithInverseCase: any}}
 */
// function detectLocale(keyCode: number) {
//     // English (US) layout has common key codes for letters and digits.
//     if (
//         (keyCode >= 65 && keyCode <= 90) || // A-Z
//         (keyCode >= 97 && keyCode <= 122) || // a-z
//         (keyCode >= 48 && keyCode <= 57) // 0-9
//     ) {
//         return "en";
//     }
//
//     // Russian (RU) layout has some specific key codes for Cyrillic letters.
//     if (
//         keyCode >= 1040 &&
//         keyCode <= 1103 // Cyrillic letters in UTF-8
//     ) {
//         return "ru";
//     }
//
//     // Default to an unknown or generic locale.
//     return "ua";
// }

const getVariants = memoize(
    string => {
        let needle = string.toLowerCase().trim();
        if (!needle) {
            return [];
        }
        let variants = [string];
        // const locale = detectLocale(needle.charCodeAt(0));
        // if (locale !== "en") return varians;

        variants = variants.concat([
            needle.split("").reduce((a, c) => {
                return (a += charMapUa[c] || c);
            }, ""),
            needle.split("").reduce((a, c) => {
                return (a += charMapRu[c] || c);
            }, ""),
            needle.split("").reduce((a, c) => {
                return (a += charMapUaInverse[c] || c);
            }, ""),
            needle.split("").reduce((a, c) => {
                return (a += charMapRuInverse[c] || c);
            }, ""),
        ]);

        return uniq(variants);
    },
    x => x.toString().toLowerCase().trim(),
);

const baseArrayFilter = (arr: string[], search: string, method: string) => {
    if (!search.trim()) {
        return null;
    }
    if (arr.length === 0) {
        return null;
    }
    const vars = getVariants(search);
    if (vars.length === 0) {
        return null;
    }
    return arr[method]((x: string) => {
        return !!vars.find(y => x.toLowerCase().includes(y));
    });
};
export const filterInArrayWithInverseCase = (arr: Array<string>, search: string): any => {
    return baseArrayFilter(arr, search, "filter");
};

export const findInArrayWithInverseCase = (arr: Array<string>, search: string): any => {
    return baseArrayFilter(arr, search, "find");
};

export const someInArrayWithInverseCase = (arr: Array<any>, search: string): any | null => {
    return baseArrayFilter(arr, search, "some");
};

/**
 *
 * @param str {string}
 * @param search {string}
 * @returns {*|null}
 */
export const findInStringWithInverseCase = memoize(
    (str, search) => {
        if (!search.trim()) {
            return null;
        }
        if (!str.length) {
            return null;
        }
        const vars = getVariants(search);
        return vars.find(x => str.toLowerCase().includes(x));
    },
    (...a) => a.join(">"),
);

