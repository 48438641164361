import React, {FC, useMemo, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import OutsideClickHandler from "react-outside-click-handler";
import {CSSTransition} from "react-transition-group";
import classNames from "classnames";
import {ChevronDownIcon, ChevronUpIcon} from "@features/icons";
import {findInStringWithInverseCase} from "@features/string-search";
import SimpleBar from "simplebar-react";
import type SimpleBarCore from 'simplebar-core'

export type Option = {
    value: string | number | undefined;
    title: string;
    onClick?: (e: Option) => void;
    className?: string
} & Record<string, any>;

interface DropdownProps {
    options?: Option[];
    value?: string;
    onSelect?: (option: Option) => void;
    placeholder?: string;
    dropUp?: boolean;
    width?: string;
    canSelectAll?: boolean;
    loading?: boolean;
    disabled?: boolean;
    renderOption?: (option: Option) => React.ReactNode;
}

const Dropdown: FC<DropdownProps> = ({
                                         options = [],
                                         dropUp,
                                         value,
                                         onSelect,
                                         placeholder,
                                         width = 'w-full',
                                         canSelectAll,
                                         loading,
                                         disabled,
                                         renderOption,
                                         ...rest
                                     }) => {
    const {t} = useTranslation();

    const [isOpen, setOpen] = useState(false);
    const [search, setSearch] = useState('');
    const [highlightIndex, setHighlightIndex] = useState(-1)
    const bar = useRef<SimpleBarCore>()
    const getOptions = () => {
        let base: Option[] = []
        if (canSelectAll) base.push({title: t('all'), value: undefined})
        if (!options) return []
        if (!search) return base.concat(options)
        return base.concat(options.filter(option => findInStringWithInverseCase(option.title, search)))
    }

    const text = useMemo(() => {
        // eslint-disable-next-line eqeqeq
        return getOptions().find((option) => option.value == value)?.title ||
            placeholder ||
            t("select");
    }, [options, value, placeholder]);

    const isSearchEnable = options && !!options.length

    const isNotFound = isSearchEnable && getOptions().length ===0 && search.length > 0

    return (
        <div className={classNames('relative', width)}>
            <OutsideClickHandler onOutsideClick={() => setOpen(false)}>
                <button
                    {...rest}
                    type='button'
                    disabled={loading || disabled}
                    onClick={() => setOpen(!isOpen)}
                    className={"input-base gap-4 w-full flex items-center"}
                >
                    <span className={classNames({skeleton: loading}, "flex-grow text-left")}>{text}</span>
                    {isOpen ? (
                        <ChevronUpIcon className={""}/>
                    ) : (
                        <ChevronDownIcon className={""}/>
                    )}
                </button>

                <CSSTransition
                    in={isOpen}
                    timeout={200}
                    classNames={"fade"}
                    onEnter={() => {
                        setTimeout(() => {
                            bar.current?.contentWrapperEl?.scrollTo(0, 0)
                        }, 50)

                    }}
                    mountOnEnter
                    unmountOnExit
                >
                    <div
                        className={classNames(
                            {
                                "top-full translate-y-2": !dropUp,
                                "bottom-full -translate-y-2": dropUp,
                            },
                            "absolute dark:bg-gray-700 bg-white with-divide-color divide-y left-0 right-0 rounded-lg shadow-md dark:shadow-xl z-10",
                        )}
                    >
                        <SimpleBar ref={e => bar.current = e as SimpleBarCore}
                                   className={'overflow-y-auto max-h-[200px] '}>
                            {
                                options && options.length > 10 && (
                                    <div className={'flex-1 w-full p-3'}>
                                        <input
                                            autoFocus={window.innerWidth > 768}
                                            onKeyUp={e => {
                                                if (e.key === 'Enter') {
                                                    if (highlightIndex >= 0) {
                                                        onSelect && onSelect(getOptions()[highlightIndex]);
                                                        setOpen(false);
                                                    } else {
                                                        onSelect && onSelect(getOptions()[0]);
                                                        setOpen(false);
                                                    }
                                                    return
                                                }
                                                if (e.key === 'ArrowDown') {
                                                    setHighlightIndex(highlightIndex + 1)
                                                }
                                                if (e.key === 'ArrowUp') {
                                                    setHighlightIndex(Math.max(highlightIndex - 1, 0))
                                                }
                                            }}
                                            value={search}
                                            onChange={e => {
                                                setHighlightIndex(-1)
                                                setSearch(e.target.value);
                                            }}
                                            type="text"
                                            className='search-input' placeholder={t('search') + '...'}/>
                                    </div>
                                )
                            }
                            {getOptions().map((row, i) => (
                                <div
                                    key={row.value + row.title}
                                    data-index={i}
                                    onClick={() => {
                                        onSelect && onSelect(row);
                                        row.onClick && row.onClick(row);
                                        setOpen(false);
                                    }}
                                    className={classNames(
                                        "px-4 py-2 cursor-pointer transition hover:text-orange-400",
                                        {"text-orange-400": row.value === value || highlightIndex === i},
                                        row.className
                                    )}
                                    title={row.title}
                                >
                                    { renderOption ? renderOption(row): row.title}
                                </div>
                            ))}

                            {
                                !!isNotFound && <p
                                className={'text-center text-gray-400 px-4 py-2 cursor-pointer transition hover:text-orange-400'}>{t("nothing found")}</p>
                            }
                        </SimpleBar>
                    </div>
                </CSSTransition>
            </OutsideClickHandler>
        </div>
    );
};

export default Dropdown;
