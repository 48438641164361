export const MILLISECOND = 1 as const

export const SECOND = 1000 as const

export const MINUTE = 60 * SECOND

export const HOUR = 60 * MINUTE

export const DAY = 24 * HOUR

export const WEEK = 7 * DAY

export const MONTH = 30 * DAY