import {useEffect, useRef, useState} from "react";
import {get, throttle} from "lodash-es";

export const useWindow = () => {
    const [size, setSize] = useState({
        innerWidth: window.innerWidth || document.documentElement.clientWidth,
        innerHeight: window.innerHeight || document.documentElement.clientWidth,
        clientWidth: document.documentElement.clientWidth,
        clientHeight: document.documentElement.clientHeight,
    });

    const cb = useRef(
        throttle(() => {
            setSize({
                innerWidth: window.innerWidth,
                innerHeight: window.innerHeight,
                clientWidth: document.documentElement.clientWidth,
                clientHeight: document.documentElement.clientHeight,
            });
        }, 400),
    );

    useEffect(() => {
        window.addEventListener("resize", cb.current);
        return () => window.removeEventListener("resize", cb.current);
    }, []);

    const isMobile = size.innerWidth < 1024
    const isDesktop = size.innerWidth >= 1024
    const isTablet = size.innerWidth < 768
    const isLarge = size.innerWidth >= 1280
    const isLandscape = !isDesktop && size.innerWidth > size.innerHeight
    const isPortrait = !isDesktop && size.innerWidth < size.innerHeight

    return {
        size,
        isMobile,
        isDesktop,
        isTablet,
        isLarge,
        isLandscape,
        isPortrait,
    };
};

export const useUserAgent = () => {
    const [userAgent, setUserAgent] = useState(window.navigator.userAgent);

    useEffect(() => {
        setUserAgent(window.navigator.userAgent);
    }, []);

    return {
        userAgent,
        // isIos: true,
        isIos: /iPad|iPhone|iPod/.test(userAgent) && !get(window, 'MSStream'),
    };
}
