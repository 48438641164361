import { create } from "zustand";

interface SidebarState {
  show: boolean;
  toggle: (val?: boolean) => void;
}

const $store = create<SidebarState>((set) => ({
  show: false,
  toggle: (val) => set({ show: val }),
}));

export const useSidebar = () => {
  const { show, toggle } = $store();

  return {
    isSidebarOpen: show,
    toggleSidebar: (val?: boolean | any) =>
      toggle(typeof val === "boolean" ? val : !show),
  };
};
