import React, {FC} from "react";
import {NavLink} from "react-router-dom";
import classNames from "classnames";
import {useRouteBuilder} from "@routes/hooks";
import {omit} from "lodash-es";

export interface HeaderTabsProps {
    items: { title: string; route: string; badge?: string, [k: string]: any }[];
    onClick?: () => void
}

export const HeaderTabs: FC<HeaderTabsProps> = ({items, onClick}) => {
    const {buildRoute} = useRouteBuilder();
    const getBadgeText = (text?: string) => {
        if (!text) return ''
        if (+text > 100) return '99+'
        return text
    }

    return (
        <div className="tab-bar">
            {items.map((x) => (
                <NavLink
                    key={x.route}
                    to={buildRoute(x.route)}
                    {...omit(x, ["route", "title", "badge"])}
                    title={x.title}
                    onClick={onClick}
                    end
                    className={({isActive}) =>
                        classNames("tab-bar-item", {active: isActive})
                    }
                >
                    {x.title}
                    {x.badge && (
                        <span
                            title={x.badge}
                            className={
                                classNames(
                                    "w-5 h-5 md:w-[30px] md:h-[30px]  flex items-center justify-center text-white font-medium rounded-full badge-info text-center ",
                                    +x.badge >= 100 ? 'text-xs' : 'text-sm md:text-xl'
                                )
                            }
                        >
              {getBadgeText(x.badge)}
            </span>
                    )}
                </NavLink>
            ))}
        </div>
    );
};
