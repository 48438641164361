import {useEffect, useRef, useState} from "react";

export enum SoundFile {
    BASE_NOTIFICATION = 'base-notification',
    ERROR_NOTIFICATION = 'error-notification',
    UNRESOLVED_NOTIFICATION = 'unresolved-orders'
}

export const useSoundPlayer = () => {
    const audioEl = useRef<HTMLAudioElement>()
    const [isPlaying, setIsPlaying] = useState(false)
    useEffect(() => {
        if (audioEl.current) return
        if (!document.getElementById('player')) {
            audioEl.current = document.createElement('audio')
            audioEl.current.id = 'player'
            document.body.appendChild(audioEl.current)
        } else {
            audioEl.current = document.getElementById('player') as HTMLAudioElement
        }
        audioEl.current.style.display = 'none'

        return () => {
            audioEl.current?.remove()
        }
    }, [])
    const play = (sound: SoundFile) => {
        if (!audioEl.current) return
        if (isPlaying) {
            // setTimeout(play, 100, sound)
            return;
        }
        try {
            audioEl.current.src = `${process.env.PUBLIC_URL}/sounds/${sound}.mp3`
            setIsPlaying(true)
            audioEl.current.play().catch(() => null).finally(() => {
                if (audioEl.current) audioEl.current.currentTime = 0
                setIsPlaying(false);
            })
        } catch (e) {

        }

    }

    const baseSound = () => play(SoundFile.BASE_NOTIFICATION)
    const errorSound = () => play(SoundFile.ERROR_NOTIFICATION)
    const unresolvedSound = () => play(SoundFile.UNRESOLVED_NOTIFICATION)

    return {
        play,
        baseSound,
        errorSound,
        unresolvedSound,
        isPlaying
    }
}