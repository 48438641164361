import {StorageManager} from "@features/storage-manager/storage-manager";
import {ROUTES} from "@routes/routes-list";
import {formatUrl} from "@shared/lib/helpers";
import {AuthResponseDTO} from "@dto/AuthResponseDTO";
import {fetcher} from "@shared/fetcher";
import {ROUTE_SIGN_IN} from "@routes/api_routes";

export const loginToAccount = async (data: {
    login: string;
    account: string;
    password: string;
}): Promise<AuthResponseDTO> => {
    return fetcher<AuthResponseDTO>(ROUTE_SIGN_IN, {
        method: 'POST',
        data,
    })
};

export const logOutFromAccount = async () => {
    StorageManager.removeFromLocal(StorageManager.KEYS.AUTH_TOKEN);
    StorageManager.removeFromLocal(StorageManager.KEYS.LAST_ACCOUNT_ID);
    StorageManager.removeFromLocal(StorageManager.KEYS.LAST_ADDRESS_ID);
    StorageManager.removeFromLocal(StorageManager.KEYS.LAST_COMPANY_ID);
    StorageManager.removeFromLocal(StorageManager.KEYS.USER_ID);
    window.location.assign(
        formatUrl(ROUTES.LOGIN, {
            lang: StorageManager.getFromLocal(StorageManager.KEYS.LAST_LANG),
        }),
    );
};
