import React, {
    FC,
    Fragment,
    lazy,
    PropsWithChildren,
    ReactElement,
    Suspense,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import {useSidebar} from "@entities/sidebar/lib/useSidebar";
import {CSSTransition} from "react-transition-group";
import {useTranslation} from "react-i18next";
import "react-datepicker/dist/react-datepicker.css";
import {NavLink} from "react-router-dom";
import {
    ArchiveIcon,
    BookIcon,
    CarIcon,
    CheckCircle,
    ChevronRightIcon,
    CloseIcon,
    LogoutIcon,
    NotificationIcon,
    PauseIcon,
    PencilIcon,
    SettingsIcon,
    StarIcon,
    StatisticIcon,
} from "@features/icons";
import classNames from "classnames";
import {inRange, throttle} from "lodash-es";
import {useRouteBuilder} from "@routes/hooks";
import {logOutFromAccount} from "@src/repositories/AuthRepository";
import {ROUTES} from "@routes/routes-list";
import {CompanyAddressSelector} from "@entities/sidebar/ui/company-address-selector";
import {useCompany, useCompanyAddress, useCompanyAddresses} from "@entities/company/lib/hooks";
import {useParams} from "react-router";
import {useAuth} from "@entities/auth/lib/hooks";
import {enumToString} from "@shared/lib/helpers";
import {COMPANY_ADDRESS_STATUS} from "@dto/CompanyAddressDTO";

const StatusSelector = lazy(
    () =>
        import(
            /*webpackChunkName: "status-selector"*/ "@entities/sidebar/ui/status-selector"
            ),
);

interface SidebarProps extends PropsWithChildren {
}

export const Sidebar: FC<SidebarProps> = ({children}) => {
    const {isSidebarOpen, toggleSidebar} = useSidebar();

    const {toOptions, data: addresses} = useCompanyAddresses(isSidebarOpen)
    const {data: addressInfo} = useCompanyAddress(isSidebarOpen)
    const {data: companyInfo} = useCompany()

    const {buildRoute} = useRouteBuilder();
    const {t} = useTranslation();
    const sidebarRef = useRef<HTMLElement>(null);
    const startXRef = useRef(0);
    const [isStatusOpen, setStatusOpen] = useState(false);
    const [showAddressSelect, setShowAddressSelect] = useState(false);
    const [redirectTo, setRedirectTo] = useState<string | undefined>();

    const {companyId, companyAddressId} = useParams()
    const {isAllCompaniesSelected} = useAuth()


    const {companyName, addressName} = useMemo(() => {
        if (isAllCompaniesSelected) return {
            companyName: t('all companies'),
            addressName: t('all addresses')
        }

        const company = toOptions()?.find(company => company.value === companyId)
        const address = company?.addresses?.find(address => address.value === companyAddressId)
        return {
            companyName: company?.title ?? '',
            addressName: address?.title ?? ''
        }
    }, [isAllCompaniesSelected, addresses, companyId, companyAddressId])

    useEffect(() => {
        if (isStatusOpen || showAddressSelect) return;
        document.body.style.overflow = isSidebarOpen ? "hidden" : "auto";

        return () => {
            document.body.style.overflow = "auto";
        };
    }, [isSidebarOpen, isStatusOpen, showAddressSelect]);

    useEffect(() => {
        if (isStatusOpen) toggleSidebar(false);
    }, [isStatusOpen]);

    useEffect(() => {
        window.addEventListener("keyup", (e) => {
            if (e.key === "Escape") {
                e.preventDefault();
                toggleSidebar(false);
            }
        });
        return () => {
            document.body.style.overflow = "auto";
            window.removeEventListener("keyup", (e) => {
                if (e.key === "Escape") {
                    e.preventDefault();
                    toggleSidebar(false);
                }
            });
        };
    }, []);

    const iconsMapping = {
        [COMPANY_ADDRESS_STATUS.ACTIVE]: (
            <CheckCircle className={"text-green-500 w-6 h-6"}/>
        ),
        [COMPANY_ADDRESS_STATUS.HIDDEN]: (
            <CheckCircle className={"text-green-500 w-6 h-6"}/>
        ),
        [COMPANY_ADDRESS_STATUS.INACTIVE]: (
            <PauseIcon className={"text-orange-400 w-6 h-6"}/>
        ),
    };

    const handleDragStart = (e: any) => {
        startXRef.current = e.touches ? e.touches[0].clientX : e.clientX;
    };

    const handleDragMove = throttle((e) => {
        if (startXRef.current && sidebarRef.current) {
            const currentX = (e.touches
                ? e.touches[0].clientX
                : e.clientX) - startXRef.current;
            const threshold = -200; // Adjust this value for sensitivity
            if (!currentX) return;
            if (!isSidebarOpen) return;
            // const currentX = sidebarRef.current.getBoundingClientRect().left;
            if (inRange(currentX, threshold, threshold / 3)) {
                toggleSidebar(false);
            }
        }
    }, 300);

    const handleDragEnd = () => {
        if (startXRef.current && sidebarRef.current) {
            startXRef.current = 0;
        }
    };

    const checkClickedLink = (route: string) => (event: any) => {
        if ([ROUTES.REVIEWS, ROUTES.MENU, ROUTES.MENU_CATEGORIES, ROUTES.MENU_MODIFIERS].includes(route) && isAllCompaniesSelected) {
            event.preventDefault()
            setRedirectTo(route)
            setShowAddressSelect(true)
        }
        toggleSidebar()
    }

    const renderLink = (route: string, text: string, icon: ReactElement, isLast = false) => {
        return (
            <NavLink
                onClick={checkClickedLink(route)}
                to={buildRoute(route)}
                className={(p) =>
                    classNames(
                        !isLast && 'border-b with-border-color',
                        "sidebar-nav-item",
                        {active: p.isActive},
                        {mobile: route === ROUTES.NOTIFICATIONS},
                    )
                }
            >
                {icon}
                <span className={"flex-grow"}>{text}</span>
                <ChevronRightIcon
                    className={"text-gray-600 dark:text-gray-300"}
                />
            </NavLink>
        );
    };

    return (
        <Fragment>
            <Suspense fallback={""}>
                <StatusSelector
                    isOpen={isStatusOpen}
                    onClose={() => {
                        setStatusOpen(false);
                        return Promise.resolve();
                    }}
                />
            </Suspense>
            <CompanyAddressSelector redirect={redirectTo} isOpen={showAddressSelect} onClose={async () => {
                setRedirectTo(undefined)
                setShowAddressSelect(false);
            }}/>
            <CSSTransition
                in={isSidebarOpen} // Set to true to show the modal, false to hide it
                timeout={300} // Duration of the animation in milliseconds
                classNames="sidebar" // Prefix for CSS class names
                unmountOnExit // Remove the component from the DOM when it's not shown
            >
                <>
                    <aside
                        data-testid={"sidebar"}
                        onTouchStart={handleDragStart}
                        onTouchMove={handleDragMove}
                        onTouchEnd={handleDragEnd}
                        ref={sidebarRef}
                        className={"sidebar z-50"}
                    >
                        <div
                            className="top-line relative bg-gray-100 dark:bg-gray-800 px-6 py-12 flex flex-col items-start">
                            <button
                                onClick={toggleSidebar}
                                className={"p-2 absolute top-4 right-4 transition"}
                            >
                                <CloseIcon
                                    className={"w-3 h-3 text-gray-600 dark:text-gray-300"}
                                />
                            </button>
                            <div>
                                <p
                                    onClick={() => {
                                        toggleSidebar()
                                        setShowAddressSelect(true);
                                    }}
                                    data-testid={"company-name"}
                                    className={classNames('group font-medium cursor-pointer text-2xl flex items-center gap-2.5 mb-1 truncate', {skeleton: !companyName})}
                                >
                                    {companyName || 'loading'}
                                    <PencilIcon
                                        className={'group-hover:opacity-100 xl:opacity-0 transition w-5 h-5'}/>
                                </p>
                                <span
                                    data-testid={"company-address"}
                                    className={
                                        classNames("text-[#939393] dark:text-gray-400 text-base truncate", {skeleton: !addressName})
                                    }
                                >
                  {addressName || 'loading'}
                </span>
                            </div>
                            {
                                companyId && !isAllCompaniesSelected &&
                              <button
                                data-testid={"status-button"}
                                onClick={() => setStatusOpen(!isStatusOpen)}
                                className={classNames({skeleton: !companyInfo}, 'btn-info font-medium text-base py-4 px-4 flex gap-2.5 items-center mt-6')}
                              >
                                  {iconsMapping[addressInfo?.status ?? 0]}
                                  {t(enumToString(addressInfo?.status ?? 0, COMPANY_ADDRESS_STATUS, 'company_address'))}
                              </button>
                            }
                        </div>
                        <div
                            className={
                                "routes-list h-fit flex flex-col justify-between  flex-grow "
                            }
                        >
                            <div
                                className={
                                    "dark:text-white  text-gray-900 px-4 text-lg"
                                }
                            >
                                {renderLink(
                                    ROUTES.NOTIFICATIONS,
                                    t("notifications"),
                                    <NotificationIcon
                                        className={"icon text-gray-600 dark:text-gray-300"}
                                    />,
                                )}
                                {renderLink(
                                    ROUTES.REVIEWS,
                                    t("navigation_reviews"),
                                    <StarIcon
                                        className={"icon text-gray-600 dark:text-gray-300"}
                                    />,
                                )}
                                {renderLink(
                                    ROUTES.CALL_COURIER,
                                    t("navigation_call_courier"),
                                    <CarIcon
                                        className={"icon text-gray-600 dark:text-gray-300"}
                                    />,
                                )}
                                {renderLink(
                                    ROUTES.ARCHIVE,
                                    t("navigation_archive"),
                                    <ArchiveIcon
                                        className={"icon text-gray-600 dark:text-gray-300"}
                                    />,
                                )}
                                {renderLink(
                                    ROUTES.STATISTICS,
                                    t("navigation_statistics"),
                                    <StatisticIcon
                                        className={"icon text-gray-600 dark:text-gray-300"}
                                    />,
                                )}
                                {renderLink(
                                    ROUTES.MENU_CATEGORIES,
                                    t("navigation_menu"),
                                    <BookIcon
                                        className={"icon text-gray-600 dark:text-gray-300"}
                                    />,
                                )}
                                {renderLink(
                                    ROUTES.SETTINGS,
                                    t("navigation_settings"),
                                    <SettingsIcon
                                        className={"icon text-gray-600 dark:text-gray-300"}
                                    />,
                                    true,
                                )}
                            </div>

                            <div
                                className={"p-4 border-t border-gray-100 dark:border-gray-600"}
                            >
                                <button
                                    data-testid={"logout-button"}
                                    onClick={logOutFromAccount}
                                    className={
                                        "btn btn-outlined text-base w-full justify-center flex gap-2.5 items-center py-4"
                                    }
                                >
                                    <LogoutIcon className={""}/>
                                    <span>{t("navigation_logout")}</span>
                                </button>
                            </div>
                        </div>
                    </aside>
                    {
                        <div
                            style={{backdropFilter: "blur(5px)"}}
                            onClick={toggleSidebar}
                            className="fixed inset-0 z-10 bg-black/40 animate__animated animate__fadeIn"
                        ></div>
                    }
                </>
            </CSSTransition>
        </Fragment>
    );
};
