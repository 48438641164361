import React, {useState} from "react";
import {ModalBase} from "@features/modal/base-modal";
import {useTranslation} from "react-i18next";
import {DotsButton} from "@shared/ui/dots-button";
import {useActionConfirmation} from "@features/confirm-action/lib/hooks";
import {ExclamationIcon} from '@features/icons'
import {confirm_action_selector, decline_action_selector} from "@features/confirm-action/lib/selectors";

export const ConfirmAction = () => {
    const {t} = useTranslation()
    const {isOpen, onConfirm, onCancel} = useActionConfirmation()
    const [loading, setLoading] = useState(false)
    const runConfirmed = async () => {
        setLoading(true)
        await onConfirm().finally(() => setLoading(false))
    }

    return <ModalBase
        maxWidth={'md:max-w-md'}
        header={t('confirm_action_title')}
        isOpen={isOpen} onClose={onCancel} footer={<div className={'flex justify-center gap-4'}>
        <DotsButton
            data-testid={confirm_action_selector}
            loading={loading}
            onClick={runConfirmed}
            className={'font-medium text-lg px-8 py-4 text-center btn flex items-center gap-2.5'}
            variant={'danger'}>
            <ExclamationIcon className={'w-5 h-5'}/>
            {t('confirm')}
        </DotsButton>
        <DotsButton
            data-testid={decline_action_selector}
            onClick={onCancel}
            className={'font-medium text-lg px-8 py-4 text-center btn flex items-center gap-2.5'}
            variant={'info'}>
            {t('cancel')}
        </DotsButton>
    </div>}>
        {t('confirm_action_text')}
    </ModalBase>
}