import React, {FC, MouseEventHandler, PropsWithChildren} from "react";
import classNames from "classnames";

export const DotsButton: FC<
  {
    icon?: React.ReactNode;
    className?: string;
    loading?: boolean;
    variant?: string | "danger" | "success" | "info" | "outline" | "white";
    onClick?: MouseEventHandler<HTMLButtonElement>
    disabled?: boolean;
    title?:string
    type?: "submit" | "button" | "reset";
  } & PropsWithChildren
> = ({
  loading,
  type = "button",
  icon,
  variant,
  className,
  children,
  disabled,
  onClick,
  ...rest
}) => {
  const FRAME_TIME = 0.12;
  const render = () => {
    let s = typeof children === "string";
    if (!s || !children) return children;
    return (
      <span>
        {children
          .toString()
          .split("")
          .map((x, i, a) => (
            <span
              data-testid={`letter-${x}`}
              style={{
                animation: loading
                  ? `loadingF ${a.length * FRAME_TIME}s ${0.48 + i * FRAME_TIME}s infinite`
                  : undefined,
              }}
              key={x + i}
            >
              {x}
            </span>
          ))}
      </span>
    );
  };
  return (
    <button
      {...rest}
      onClick={!loading ? onClick : undefined}
      type={type}
      title={typeof children === "string" ? children : rest.title}
      className={classNames(className, "btn", {
        "btn-loading": loading,
        disabled,
        [`btn-${variant}`]: !!variant,
      })}
    >
      {icon}
      {render()}
    </button>
  );
};
