import React, {FC, useEffect, useState} from "react";
import {createPortal} from "react-dom";
import classNames from "classnames";
import {useTranslation} from "react-i18next";
import {DotsButton} from "@shared/ui/dots-button";
import {CloseIcon} from "@features/icons";
import {useUserAgent, useWindow} from "@shared/lib/hooks";

interface ModalProps {
    onClose?: () => Promise<void>;
    onConfirm?: () => Promise<void>;
    isOpen: boolean;
    children: React.ReactNode;
    footer?: React.ReactNode;
    header?: React.ReactNode;
    maxWidth?: string;
    withCloseButton?: boolean;
    fillContent?: boolean;
    borderOnHeader?: boolean;
    borderOnFooter?: boolean;
    expandWidth?: boolean;
    headerSpacing?: string;
    fixHeader?: boolean;
    canFloatBottom?: boolean;
    useContentScroll?: boolean;
    confirmButtonText?: string;
}

export const ModalBase: FC<ModalProps> = ({
                                              onClose,
                                              header,
                                              footer,
                                              onConfirm,
                                              children,
                                              withCloseButton = true,
                                              fillContent = true,
                                              borderOnHeader = true,
                                              borderOnFooter = true,
                                              expandWidth = false,
                                              fixHeader = true,
                                              headerSpacing = "px-6 py-4",
                                              maxWidth,
                                              canFloatBottom = true,
                                              useContentScroll = true,
                                              isOpen,
                                              confirmButtonText,
                                          }) => {
    const CLOSE_TIME = 200;
    const [isCLosing, setIsClosing] = useState(false);
    const [loading, setLoading] = useState(false);
    const {t} = useTranslation();
    const {isIos} = useUserAgent()
    const {isLandscape} = useWindow()

    useEffect(() => {
        const handleKeyDown = (event: unknown) => {
            if (event instanceof KeyboardEvent && event.key === "Escape") {
                event.preventDefault();
                closeCb();
            }
        };

        document.addEventListener("keydown", handleKeyDown);

        return () => {
            document.removeEventListener("keydown", handleKeyDown);
        };
    }, [onClose]);

    useEffect(() => {
        const root = document.querySelector("#modal-root");
        if (root && root.children.length > 0 && !isOpen) return;
        document.body.style.overflow = isOpen ? "hidden" : "auto";
        return () => {
            document.body.style.overflow = 'auto'
        }
    }, [isOpen]);

    const closeCb = () => {
        if(loading) return
        setIsClosing(true);
        setTimeout(() => {
            onClose && onClose();
            setIsClosing(false);
        }, CLOSE_TIME * 1.2);
    };

    const confirmCb = () => {
        if (!onConfirm) return;
        setLoading(true);
        onConfirm()
            .then(() => setLoading(false))
            .then(closeCb)
            .catch(() => setLoading(false));
    };

    const canScroll = useContentScroll || isLandscape

    const renderHeader = () => {
        if (!header) return null;
        return (
            <div
                className={classNames(
                    {"border-b": borderOnHeader},
                    headerSpacing,
                    "with-border-color text-gray-900 dark:text-white font-medium text-xl",
                )}
            >
                {withCloseButton && (
                    <button
                        className={"p-2 z-10 cursor-pointer absolute top-4 right-3"}
                        data-testid={"modal-close"}
                        onClick={closeCb}
                    >
                        <CloseIcon className={""}/>
                    </button>
                )}
                {header}
            </div>
        );
    };

    if (!isOpen) return null;
    return createPortal(
        <div
            className={classNames({
                'md:items-center items-end': canFloatBottom,
                'items-center': !isIos && !canFloatBottom,
                'pt-[10svh] items-start': isIos && !canFloatBottom
            }, 'fixed inset-0 w-svw h-svh flex justify-center z-50')}
            style={{backdropFilter: "blur(5px)"}}
        >
            <div
                onClick={closeCb}
                style={{
                    animationDuration: CLOSE_TIME + "ms",
                    backdropFilter: "blur(5px)",
                }}
                className={classNames(
                    "bg-black opacity-40 fixed inset-0 animate__animated animate__slideIn",
                )}
            ></div>
            <div
                style={{
                    animationDuration: CLOSE_TIME + "ms",
                }}
                className={classNames(
                    maxWidth || "xl:max-w-2xl",
                    {"h-full": expandWidth, "xl:h-auto": !expandWidth},
                    "md:rounded-2xl",
                    "rounded-t-2xl",
                    isIos && !canFloatBottom && 'mx-8',
                    !canFloatBottom && 'rounded-b-2xl mx-4',
                    "basic-block xl:mx-4 flex flex-col w-full mx-auto md:mx-6  shadow-md animate__animated max-h-[90svh]",
                    {
                        animate__fadeOutDown: isCLosing,
                        animate__fadeInUp: !isCLosing,
                    },
                )}
            >
                {fixHeader && renderHeader()}
                <div
                    className={classNames(
                        fillContent
                            ? "bg-[#F7F7F7] dark:bg-gray-800 flex-grow  p-4 md:p-6"
                            : "lg:px-6 px-4",
                        expandWidth && "flex-grow",
                        canScroll && 'overflow-auto',
                        "modal-content",
                    )}
                >
                    {!fixHeader && renderHeader()}
                    {children}
                </div>

                {(onClose || onConfirm || footer) && (
                    <div
                        className={classNames(
                            {"border-t": borderOnFooter},
                            "modal-buttons with-border-color flex gap-2 lg:px-6 lg:py-6 px-4 py-4 justify-center  flex-col xl:flex-row",
                        )}
                    >
                        {onConfirm && !footer && (
                            <DotsButton
                                variant={"white"}
                                data-testid={"modal-confirm"}
                                loading={loading}
                                className={classNames("btn text-lg px-8 py-4")}
                                onClick={confirmCb}
                            >
                                {confirmButtonText ?? t("base_confirm")}
                            </DotsButton>
                        )}

                        {footer}
                    </div>
                )}
            </div>
        </div>,
        document.getElementById("modal-root") as HTMLElement,
    );
};
